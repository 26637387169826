<template><v-app-bar app color="indigo" dark>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-app-bar-nav-icon v-bind="attrs" v-on="on" @click.stop="menu = !menu"></v-app-bar-nav-icon>
    </template>
    <span>Menú</span>
  </v-tooltip>

  <v-navigation-drawer v-model="menu" height="505px" width="280px" app absolute temporary>
    <!-- <v-list nav dense>
        
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
        
        <v-list-item
          v-for="([title,icon, link],i) in items"
          :key="i"
          link
          :to="link"
        >
          <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        </v-list-item-group>
      </v-list> -->
    <template v-slot:prepend>
      <v-list-item two-line>
        <v-list-item-avatar>
          <img src="../../../public/AvatarDr.png" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ usuario }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-divider></v-divider>
    <v-list dense>
      <v-list-group no-action prepend-icon="mdi-calendar-clock">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Turnos</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item v-for="(turno, i) in turnos" :key="i" link @click="verTurnos(i)">
          <v-list-item-title v-text="turno[0]"></v-list-item-title>
        </v-list-item>
      </v-list-group>

      <v-divider></v-divider>
      <v-list-group no-action prepend-icon="mdi-hospital">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Historias Clínicas</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item v-for="(hc, i) in hcs" :key="i" :to="hc[1]" link>
          <v-list-item-title v-text="hc[0]"></v-list-item-title>
        </v-list-item>
      </v-list-group>

      <v-divider></v-divider>

      <v-list-group no-action prepend-icon="mdi-key-variant">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Cuenta</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item v-for="(conf, i) in confs" :key="i" :to="conf[1]" link>
          <v-list-item-title v-text="conf[0]"></v-list-item-title>
        </v-list-item>
      </v-list-group>
    </v-list>
    <template v-slot:append>
      <div class="pa-2">
        <v-btn @click="logout()" block>
          <v-icon left>mdi-power-standby</v-icon>
          Cerrar Sesión</v-btn>
      </div>
    </template>
  </v-navigation-drawer>
  <v-divider vertical></v-divider>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="ml-3" icon small v-bind="attrs" v-on="on" to="/Home">
        <v-icon>mdi-home</v-icon>
      </v-btn>
    </template>
    <span>Página de Inicio</span>
  </v-tooltip>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="ml-2" icon x-small v-bind="attrs" v-on="on" @click="cambiarContrasena">
        <v-icon>mdi-wrench</v-icon>
      </v-btn>
    </template>
    <span>Cambiar Contraseña</span>
  </v-tooltip>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="ml-2" v-bind="attrs" v-on="on" icon small @click="logout">
        <v-icon>mdi-power-standby</v-icon>
      </v-btn>
    </template>
    <span>Cerrar Sesión</span>
  </v-tooltip>
  <v-spacer></v-spacer>
  <h2 v-if="!isMobile() && this.$store.state.nombreInstitucion">
    {{ this.$store.state.nombreInstitucion }}
  </h2>
  <v-spacer></v-spacer>
  <span class="app-version">v.{{ $appVersion }}</span>
  <!-- <v-toolbar-title>GesMed</v-toolbar-title> -->
  <router-link to="/Home">
    <v-img src="../../assets/isologo.png" max-height="50" max-width="150" contain></v-img>
  </router-link>

  <CambiarContrasena :visible="showCambiarContrasena" :usu="usuario" @close="showCambiarContrasena = false">
  </CambiarContrasena>
</v-app-bar></template>

<script>
import router from "../../router/index.js";
import CambiarContrasena from "../Cuenta/CambiarContrasena.vue";
export default {
  data() {
    return {
      showCambiarContrasena: false,
      menu: false,
      turnos: [
        ["Agenda Semanal (PC)", "/GestionTurnosSemanaWide"],
        ["Agenda Semanal (Celular)", "/GestionTurnosSemana"],
        ["Mis Turnos", "/MisTurnos"],
      ],

      hcs: [["Ver Informes Médicos", "/Informes"]],

      confs: [["Modificar Datos", "/ConfCuenta"]],
      group: null,
      usuario: null,
    };
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  methods: {
    isMobile() {
      if (window.innerWidth < 992) {
        return true;
      } else {
        return false;
      }
    },
    logout() {
      this.$store.dispatch("logout");
    },
    cambiarContrasena() {
      this.showCambiarContrasena = true;
    },
    verTurnos(i) {
      if (i == 0) {
        this.$store.dispatch("setVista", 0);
        router.push("/BuscarInstitucion");
      } else {
        if (i == 1) {
          this.$store.dispatch("setVista", 1);
          router.push("/BuscarInstitucion");
        } else {
          router.push("/MisTurnos");
        }
      }
    },
  },
  created() {
    this.usuario = this.$store.state.nombreUsuarioPaciente.toString().trim();
  },
  components: {
    CambiarContrasena,
  },
};
</script>
<style scoped>
.app-version {
  color: white;
  font-size: 0.75rem;
  margin-right: 1.5rem;
}
</style>
