<template>
  <div class="container">
    <v-row
      justify="center"
      v-if="urlInstitucion && this.$store.state.esEmpresa"
    >
      <a href="https://www.icasarg.com" target="_blank"
        ><v-img
          src="../assets/icas.png"
          max-height="202"
          max-width="360"
          contain
        ></v-img
      ></a>
    </v-row>
    <v-row justify="center" v-if="urlInstitucion">
      <v-col style="text-align: center" cols="12">
        <v-alert color="blue-grey" dark dense prominent>
          <h2 v-if="!isMobile()">{{ nombreInstitucion }}</h2>
          <h3 v-else>{{ nombreInstitucion }}</h3>
        </v-alert>
      </v-col>
    </v-row>
    <v-row justify="center" v-else>
      <v-col style="text-align: center" cols="12">
        <v-alert color="blue-grey" dark dense prominent>
          <h2 v-if="!isMobile()">SiGeMed</h2>
          <h3 v-else>SiGeMed</h3>
        </v-alert>
      </v-col>
    </v-row>

    <v-row justify="space-between">
      <v-col cols="12" md="6" sm="6">
        <v-form ref="form" v-model="valid" :lazy-validation="lazy">
          <v-card
            elevation="24"
            class="mx-auto px-auto mb-1 pb-1"
            color="#3894C3"
            dark
            raised
            max-width="500px"
          >
            <v-card-title class="headline layout justify-center mb-3"
              >Portal del Paciente</v-card-title
            >
            <v-card-subtitle class="layout justify-center"
              >Inicio de Sesión</v-card-subtitle
            >
            <v-container class="pt-0">
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    v-model="usuario"
                    prepend-icon="mdi-account"
                    :rules="nameRules"
                    label="Documento"
                    required
                    @keypress="onlyNumber"
                    @keypress.enter="signin"
                  ></v-text-field>

                  <v-text-field
                    v-model="pass"
                    prepend-icon="mdi-lock"
                    :rules="passRules"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    label="Contraseña"
                    :type="showPassword ? 'text' : 'Password'"
                    required
                    @keypress.enter="signin"
                    @click:append="showPassword = !showPassword"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <div class="pull-right">
              <v-card-title class="headline layout justify-center pa-0">
                <v-btn
                  color="success"
                  class="mr-4"
                  :disabled="!valid || loading"
                  :loading="loading"
                  @click="signin"
                  >Acceder</v-btn
                >
              </v-card-title>
              <v-card-title
                class="headline layout justify-center pb-1 mb-1 mt-3"
              >
                <v-btn
                  @click="recuperarPass"
                  x-small
                  class="ma-2"
                  tile
                  outlined
                  color="white"
                >
                  <v-icon left>mdi-lock</v-icon>¿Olvidaste tu contraseña?
                </v-btn>
              </v-card-title>
            </div>

            <br />
          </v-card>
        </v-form>
        <v-card
          elevation="24"
          class="mx-auto px-auto mt-3 mb-4"
          color="#3894C3"
          dark
          max-width="500px"
        >
          <v-card-title>
            <v-spacer></v-spacer>
            <h5>¿No tienes una cuenta?</h5>
            <v-btn color="warning" small class="ml-3" @click="registrarse()"
              >Registrate</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-title>

          <RecuperoPass
            :visible="showRecuperoPass"
            @close="showRecuperoPass = false"
          ></RecuperoPass>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" sm="6">
        <v-card color="#D3F9F2" dark max-width="500px" class="mx-auto px-auto">
          <v-card-title>
            <v-icon large left color="green">mdi-youtube-tv</v-icon>
            <span class="title font-weight-light" style="color: green;"
              >Videos Tutoriales</span
            >
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="12" md="4" sm="12">
                <v-row align="center" justify="center">
                  <v-card
                    href="https://youtu.be/7S9UFeXQ75c"
                    target="_blank"
                    dark
                    color="green"
                    width="300"
                  >
                    <v-card-title class="headline layout justify-center">
                      <v-col cols="12" class="headline layout justify-center">
                        <h3>
                          <v-icon x-large>mdi-account-multiple</v-icon>
                        </h3>
                      </v-col>
                      <h6>Crear cuenta</h6>
                    </v-card-title>
                  </v-card>
                </v-row>
              </v-col>

              <v-spacer></v-spacer>

              <v-col cols="12" md="4" sm="12">
                <v-row align="center" justify="center">
                  <v-card
                    href="https://youtu.be/TSiOTnjritQ"
                    target="_blank"
                    dark
                    color="green"
                    width="300"
                  >
                    <v-card-title class="headline layout justify-center">
                      <v-col cols="12" class="headline layout justify-center">
                        <h3>
                          <v-icon x-large>mdi-calendar-multiple</v-icon>
                        </h3>
                      </v-col>
                      <h6>Sacar un turno</h6>
                    </v-card-title>
                  </v-card>
                </v-row>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </v-card-text>
        </v-card>
        <!-- <v-card
          class="mt-3 mx-auto px-auto"
          color="#D3F9F2"
          dark
          max-width="500px"
        >
          <v-card-title class="justify-center">
            <span class="title font-weight-light" style="color: green;"
              >¿Necesita ayuda? Haga click en el ícono</span
            >
          </v-card-title>
          <v-card-text>
            <v-row align="center" justify="center">
              <v-btn
                icon
                href="https://api.whatsapp.com/send?phone=5493547461089&text=Hola%21%20Necesito%20ayuda%20con%20el%20portal%20del%20paciente"
              >
                <v-icon large color="green">mdi-whatsapp</v-icon>
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card> -->
        <v-card
          class="mt-3 mx-auto px-auto"
          color="#D3F9F2"
          dark
          max-width="500px"
          v-if="urlInstitucion"
        >
          <v-card-text>
            <v-row align="center" justify="center">
              <v-btn
                color="warning"
                small
                class="ml-3"
                @click="verProfesionales()"
                >VER PROFESIONALES Y HORARIOS DE ATENCION</v-btn
              >
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <VerCronogramas
      :visible="showCronogramas"
      :idInstitucion="idInstitucion"
      @close="showCronogramas = false"
    ></VerCronogramas>
  </div>
</template>
<script>
import axios from "../axios-auth.js";
import router from "../router/index.js";
import RecuperoPass from "../components/RecuperoPass.vue";
import VerCronogramas from "../components/VerCronogramasActivos.vue";

export default {
  name: "Login",
  props: {
    urlInstitucion: {
      required: false,
      default: "",
    },
  },
  data: () => ({
    valid: false,
    lazy: false,
    showPassword: false,
    loading: false,
    showRecuperoPass: false,
    usuario: "",
    nameRules: [(v) => !!v || "El documento es un dato requerido"],
    pass: "",
    passRules: [(v) => !!v || "La contraseña es un dato requerido"],
    nombreInstitucion: "",
    idInstitucion: 0,
    showCronogramas: false,
  }),
  methods: {
    verProfesionales() {
      this.showCronogramas = true;
    },
    showAlertMessage(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: alertInfo.timer,
      });
    },
    isMobile() {
      if (window.innerWidth < 400) {
        return true;
      } else {
        return false;
      }
    },
    scrollToTop() {},
    recuperarPass() {
      this.showRecuperoPass = true;
    },
    signin() {
      if (this.valid) {
        const formData = {
          usuario: this.usuario,
          pass: this.pass,
        };
        this.loading = true;

        let self = this;
        axios
          .get("/Institucional/Login?", {
            params: {
              usuario: formData.usuario,
              pass: formData.pass,
            },
          })
          .then(function(response) {
            if (response.data == "Usuario Inexistente") {
              self.loading = false;
              self.showAlert({
                icon: "error",
                title: "Documento o Contraseña inválidos",
                vm: self,
              });
            } else {
              if (response.data == "Usuario Inactivo") {
                self.loading = false;
                self.showAlert({
                  icon: "error",
                  title: "Cuenta Inactiva",
                  vm: self,
                });
              } else {
                self.$store.commit("saveUserData", {
                  token: response.data.token,
                  idUsuarioPaciente: response.data.idUsuarioPaciente,
                  nombreUsuarioPaciente: response.data.nombreUsuarioPaciente,
                });

                localStorage.setItem("token", response.data.token);
                localStorage.setItem(
                  "idUsuarioPaciente",
                  response.data.idUsuarioPaciente
                );
                self.loading = false;
                self.showAlert({
                  icon: "success",
                  title: "Ingreso Exitoso",
                  vm: self,
                });
                router.push({ path: "/Home" });
              }
            }
          })
          .catch(function(error) {
            this.loading = false;
          });
      }
    },
    showAlert(alertInfo) {
      const Toast = alertInfo.vm.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        onOpen: (toast) => {
          toast.addEventListener("mouseenter", alertInfo.vm.$swal.stopTimer);
          toast.addEventListener("mouseleave", alertInfo.vm.$swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
      });
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    registrarse() {
      router.push("/Register");
    },
  },
  async created() {
    this.$store.commit("clearAuthData");
    let self = this;

    if (this.urlInstitucion) {
      this.$store.commit("setUrlInstitucion", this.urlInstitucion);
      axios
        .get("/Login/GetDatosInstitucion", {
          params: {
            urlInstitucion: this.urlInstitucion,
          },
        })
        .then((response) => {
          this.$store.commit("setIdInstitucion", response.data.idInstitucion);
          this.$store.commit(
            "setNombreInstitucion",
            response.data.nombreInstitucion
          );
          this.$store.commit("setUrlInstitucion", response.data.urlInstitucion);
          this.$store.commit("setEsEmpresa", response.data.esEmpresa);
          this.nombreInstitucion = response.data.nombreInstitucion;
          this.idInstitucion = response.data.idInstitucion;
        })
        .catch(async () => {
          await self.showAlertMessage({
            icon: "error",
            title: "La institución no existe!",
            vm: self,
          });
          router.push("/Login");
        });
    } else {
      this.$store.commit("resetDatosInstitucion");
    }
  },
  components: {
    RecuperoPass,
    VerCronogramas,
  },
};
</script>
