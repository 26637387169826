<template>
  <v-dialog v-model="show" persistent max-width="600px" transition="dialog-bottom-transition">
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Cambiar Contraseña</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
             <v-text-field
                v-model="usuario"
                prepend-icon="mdi-account"
                label="Usuario"
                disabled   
                      
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="pass"
                prepend-icon="mdi-lock"
                :rules="requiredRules"
                label="Contraseña Actual"
                type="Password"
                required
               
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="passNuevo"
                prepend-icon="mdi-lock"
                @blur="$v.passNuevo.$touch()"
                label="Contraseña Nueva"
                type="Password"
                required
                maxlength="30"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="passNuevoRepetir"
                prepend-icon="mdi-lock"
                @blur="$v.passNuevoRepetir.$touch()"
                label="Repetir Contraseña Nueva"
                type="Password"
                required
                maxlength="30"
              ></v-text-field>
              </v-col>
            <v-col cols="12" class="pa-0">
                <ul>
                <li style="color:red; font-size:13px;margin-bottom:4px" v-show="!$v.passNuevoRepetir.sameAs">Las contraseñas no coinciden</li>
                <li style="color:red; font-size:13px" v-show="!$v.passNuevo.minLen">La nueva contraseña debe tener al menos 6 caracteres</li>
                </ul>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" :disabled="!valid || $v.$invalid" @click.stop="modificarContrasena">Guardar</v-btn>
          <v-btn color="error" @click.stop="show=false">Salir</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import axios from "../../axios-auth.js";
import router from "../../router/index.js";
import {required, minLength, sameAs} from 'vuelidate/lib/validators'  
export default {
  data: (vm) => ({
    valid: false,
    lazy: false,
    requiredRules: [(v) => !!v || "Dato obligatorio",],
    pass: '',
    passNuevo: '',
    passNuevoRepetir: '',
    idUsuarioPaciente: ''
  }),
  props: ["visible", "usu"],
  computed: {
      show: {
      get() {
        this.idUsuarioPaciente = this.$store.state.idUsuarioPaciente
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$refs.form.resetValidation();
          this.vaciarModels();
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      }
    },
    usuario: {
      get(){
        return this.usu
      }
    }
  },
  methods: {
      vaciarModels() {
          this.pass= '',
          this.passNuevo = '',
          this.passNuevoRepetir = ''
      },
      modificarContrasena(){
        this.$swal({
        title: "Modificar Contraseña",
        text: "¿Desea modificar la contraseña?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true
      }).then(result => {
        if (result.value) {
          const datosUsuario = {
            pass: this.pass,
            passNuevo: this.passNuevo,
            idUsuarioPaciente: this.idUsuarioPaciente
          };
         
          let self = this;
          axios
            .get("/Usuario/CambiarPassUsuarioPaciente?",{
                params: {
                    pass: datosUsuario.pass,
                    passNuevo: datosUsuario.passNuevo,
                    idUsuarioPaciente: datosUsuario.idUsuarioPaciente
                }
            })
            .then(response => {
              if(response.data == "incorrecta")
              {
                self.showAlert({
                icon: "error",
                title: "Contraseña actual incorrecta",
                vm: self
              });
              }
              else
              {
              self.$refs.form.resetValidation();
              self.vaciarModels();
              document.getElementsByClassName(
                "v-dialog--active"
              )[0].scrollTop = 0;
              self.showAlert({
                icon: "success",
                title: "Contraseña modificada con éxito",
                vm: self
              });
              self.$emit("close")
              }
            })
            .catch(error => {
              
              self.showAlert({
                icon: "error",
                title: "La contraseña no pudo ser modificada",
                vm: this
              });
            });
        }
      });
      },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2000
      });
    }
     
  },
  validations: {
       passNuevo: {
        required,
        minLen: minLength(6)
      },
      passNuevoRepetir: {
        //sameAs: sameAs('password')
        sameAs: sameAs(vm => {
          return vm.passNuevo;
        })
      },
  }
};
</script>