<template>
  <div class="container">
    <v-dialog v-model="show" max-width="400px" persistent transition="dialog-bottom-transition">
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-card>
        <v-toolbar dark color="primary">
          
          <v-toolbar-title>
            Recuperar Contraseña
           
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
              <v-alert
          color="purple"
          dark
          dismissible
          outlined
        >El email debe coincidir con el que indicó al dar de alta el usuario. Se le enviará un correo con una nueva contraseña aleatoria.</v-alert>
          <v-row dense>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="paciente.dni"
                prepend-icon="mdi-account"
                
                label="D.N.I"
                dense
                required
                @keypress="onlyNumber"
               
                :rules="requiredRules"
                maxlength="9"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
              <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="paciente.mail"
                prepend-icon="mdi-email"
                label="E-mail"
                :rules="emailRules"
                dense
                maxlength="80"
              ></v-text-field>
            </v-col>

          </v-row>
        </v-card-text>
        <v-card-actions>
         
          <v-spacer></v-spacer>
          <v-btn color="success" :disabled="!valid" @click.stop="send">Enviar Email</v-btn>

          <v-btn color="error" @click.stop="show=false">Volver</v-btn>
        </v-card-actions>
       
      </v-card>
    </v-form>
    </v-dialog>
  </div>
</template>

<script>
import axios from "../axios-auth.js";
import router from "../router/index.js";
export default {
  data: (vm) => ({
    horaTurno: null,
    paciente: {
      dni: null,     
      mail: null,
      
    },
       
    valid: false,
    lazy: false,
    requiredRules: [(v) => !!v || "Dato obligatorio"],
    emailRules: [
      (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "E-mail inválido",
        (v) => !!v || "Dato obligatorio"
    ],
     passwordRules: [
      (v) => (v && v.length > 5)|| "Mínimo 6 caracteres",
        (v) => !!v || "Dato obligatorio"
    ],
  }),
   props: [
    "visible",
  ],

  computed: {
    show: {
      get() {       
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$refs.form.resetValidation();
          this.vaciarModels();
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    send() {
      this.$swal({
        title: "Enviar email de recupero de contraseña",
        text: "¿Desea enviar un correo para restaurar su contraseña?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const datosUsuario = {
            
            nrodocumento: this.paciente.dni,
            email: this.paciente.mail,
            
          };
          
          let self = this;
          axios
            .post("/Institucional/RecuperarPass", datosUsuario)
            .then((response) => {
                    if(response.data == "error")
                    {
                      self.showAlert({
                      icon: "error",
                      title: "No existe ningún usuario registrado con esos datos. Revise el D.N.I y el email ingresados por favor.",
                      vm: self,
                    });
                      
                    }
                    else{
                      if(response.data == "inactiva")
                      {
                             self.showAlert({
                      icon: "error",
                      title: "La cuenta aún no ha sido activada.",
                      vm: self,
                    });
                      }
                      else
                      {
                    self.showAlert({
                      icon: "success",
                      title: "Se envió un mail a la casilla de correo registrada con su nueva contraseña. Revise por favor su correo no deseado o spam.",
                      vm: self,
                    });
                    this.show = false;
                      }
                    }
                    
            })
            .catch((error) => {
              
              self.showAlert({
                icon: "error",
                title: "El correo no pudo ser enviado. Intente mas tarde por favor.",
                vm: this,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
   
    onlyNumber($event) {
    
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    
    
    vaciarModels() {      
      this.paciente.mail = "";
      this.paciente.dni = "";
     
     
    },

    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 4200,
      });
    },
  
  },
 
  created() {
    this.vaciarModels();
   
  },
};
</script>