import Vue from "vue";
import Vuex from "vuex";
import axios from "../axios-auth.js";
import router from "../router/index.js";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: "",
    idUsuarioPaciente: null,
    nombreUsuarioPaciente: null,
    vistaTurero: null,
    idInstitucion: 0,
    urlInstitucion: "",
    nombreInstitucion: "",
    esEmpresa: false,
  },

  mutations: {
    setVista(state, vista) {
      state.vista = vista;
    },
    setDni(state, nroDni) {
      state.dniPaciente = nroDni;
    },
    setRutaSalidaHC(state, value) {
      state.rutaSalidaHC = value;
    },
    setIndexProf(state, value) {
      state.indexProf = value;
    },
    saveUserData(state, userData) {
      state.token = userData.token;
      state.idUsuarioPaciente = userData.idUsuarioPaciente;
      state.nombreUsuarioPaciente = userData.nombreUsuarioPaciente;

      axios.defaults.headers.common["Authorization"] =
        "Bearer " + userData.token;
    },
    clearAuthData(state) {
      state.idUsuarioPaciente = null;
      state.nombreUsuarioPaciente = null;
      state.token = null;
      axios.defaults.headers.common["Authorization"] = null;
    },
    armarTurnos(state, turnosData) {
      state.listaTurnos = turnosData;
    },
    setIdInstitucion(state, idInstitucion) {
      state.idInstitucion = idInstitucion;
    },
    setNombreInstitucion(state, nombreInstitucion) {
      state.nombreInstitucion = nombreInstitucion;
    },
    setUrlInstitucion(state, urlInstitucion) {
      state.urlInstitucion = urlInstitucion;
    },
    setEsEmpresa(state, esEmpresa) {
      state.esEmpresa = esEmpresa;
    },
    resetDatosInstitucion(state) {
      state.idInstitucion = 0;
      state.nombreInstitucion = "";
      state.urlInstitucion = "";
      state.esEmpresa = false;
    },
    updateAccessToken: (state, accessToken) => {
      state.token = accessToken;
      axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    },
  },

  actions: {
    setVista({ commit }, vista) {
      commit("setVista", vista);
    },
    setDni({ commit }, nroDni) {
      commit("setDni", nroDni);
    },
    setIndexProf({ commit }, value) {
      commit("setIndexProf", value);
    },
    setRutaSalidaHC({ commit }, value) {
      commit("setRutaSalidaHC", value);
    },
    login({ commit, dispatch }, authData) {
      axios
        .get("/Institucional/Login?", {
          params: {
            usuario: authData.usuario,
            pass: authData.pass,
          },
        })
        .then(function(response) {
          if (response.data == "Usuario Inexistente") {
            dispatch("showAlert", {
              icon: "error",
              title: "Documento o Contraseña inválidos",
              vm: authData.vm,
            });
          } else {
            if (response.data == "Usuario Inactivo") {
              dispatch("showAlert", {
                icon: "error",
                title: "Cuenta Inactiva",
                vm: authData.vm,
              });
            } else {
              commit("saveUserData", {
                token: response.data.token,
                idUsuarioPaciente: response.data.idUsuarioPaciente,
                nombreUsuarioPaciente: response.data.nombreUsuarioPaciente,
              });

              localStorage.setItem("token", response.data.token);
              localStorage.setItem(
                "idUsuarioPaciente",
                response.data.idUsuarioPaciente
              );
              router.push({ path: "/Home" });
              dispatch("showAlert", {
                icon: "success",
                title: "Ingreso Exitoso",
                vm: authData.vm,
              });
            }
          }
        })
        .catch(function(error) {})
        .then(function() {});
    },

    showAlert({}, alertInfo) {
      const Toast = alertInfo.vm.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        onOpen: (toast) => {
          toast.addEventListener("mouseenter", alertInfo.vm.$swal.stopTimer);
          toast.addEventListener("mouseleave", alertInfo.vm.$swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
      });
    },
    logout({ commit, state }) {
      commit("clearAuthData");
      localStorage.removeItem("token");
      localStorage.removeItem("idUsuarioPaciente");

      if (state.idInstitucion) {
        router.push({ path: `/portal/${state.urlInstitucion}` });
      } else {
        router.push({ path: "/" });
      }
    },
    armarTurnos({ commit }, data) {
      this.state.listaTurnos = [];
      const idInstitucion = this.state.usuario.institucion.idInstitucion;
      axios
        .get("/Turnos/ArmarTurnos?", {
          params: {
            idProfesional: data.idProfesional,
            fecha: data.fecha,
            idInstitucion: idInstitucion,
          },
        })
        .then(function(response) {
          commit("armarTurnos", response.data);
        })
        .catch(function(error) {});
    },
    setAuth({ commit }) {
      commit("updateAccessToken", localStorage.getItem("token"));
    },
  },

  modules: {},
  plugins: [
    createPersistedState({
      storage: window.localStorage,
    }),
  ],
});
