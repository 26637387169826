<template>
  <v-dialog v-model="show" hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Profesionales</v-toolbar-title>
      </v-toolbar>

      <v-data-table
        :headers="headers"
        :items="this.listaCronogramas"
        class="elevation-1"
        :hide-default-footer="true"
        locale="es-ar"
        item-key="idCronograma"
        :disable-sort="true"
      >
      </v-data-table>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click.stop="show = false">Salir</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
</style>
<script>
import axios from "../axios-auth";

export default {
  data: (vm) => ({
    listaCronogramas: [],
    headers: [
      { text: "Día", value: "dia" },
      { text: "Profesional", value: "nombreProfesional" },
      { text: "Especialidad", value: "especialidad" },
      { text: "Hora Desde", value: "horaDesde" },
      { text: "Hora Hasta", value: "horaHasta" },
    ],
  }),
  computed: {
    show: {
      get() {
        if (this.visible) this.cargarCronogramas();
        return this.visible;
      },
      set(value) {
        if (!value) {
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.listaCronogramas = [];
          this.$emit("close");
        }
      },
    },
  },
  props: ["visible", "idInstitucion"],
  methods: {
    cargarCronogramas() {
      const datosCronograma = {
        idInstitucion: this.idInstitucion,
      };
      let self = this;
      axios
        .get("/Institucional/ConsultarCronogramasInstitucion?", {
          params: {
            idInstitucion: datosCronograma.idInstitucion,
          },
        })
        .then((response) => {
          self.listaCronogramas = response.data;
        })
        .catch((error) => {
          self.listaCronogramas = [];
        });
    },
  },
};
</script>
