import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'

Vue.use(VueRouter)
  const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/portal/:urlInstitucion',
    name: 'LoginInstitucion',
    component: () => import(/* webpackChunkName: "about" */ '../components/Login.vue'),
    props: true
  },
  {
    path: '/Register',
    name: 'Register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Register.vue')
  },
  {
    path: '/BuscarInstitucion',
    name: 'BuscarInstitucion',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Turnos/BuscarInstitucion.vue')
  },
  {
    path: '/MisTurnos',
    name: 'MisTurnos',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Turnos/MisTurnos.vue')
  },   
  {
    path: '/ConfCuenta',
    name: 'ConfCuenta',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Cuenta/ConfCuenta.vue')
  },
  {
    path: '/Informes',
    name: 'Informes',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/HistClin/Informes.vue')
  },
  {
    path: '/Home',
    name: 'Home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
 
  {
    path: '/TutorialesTurnos',
    name: 'TutorialesTurnos',
    component: () => import(/* webpackChunkName: "about" */ '../components/Tutoriales/TutorialesTurnos.vue')
  },
  {
    path: '/TutorialesCuenta',
    name: 'TutorialesCuenta',
    component: () => import(/* webpackChunkName: "about" */ '../components/Tutoriales/TutorialesCuenta.vue')
  },
  {
    path: '/TutorialesCaja',
    name: 'TutorialesCaja',
    component: () => import(/* webpackChunkName: "about" */ '../components/Tutoriales/TutorialesCaja.vue')
  },
  {
    path: '/TutorialesGestion',
    name: 'TutorialesGestion',
    component: () => import(/* webpackChunkName: "about" */ '../components/Tutoriales/TutorialesGestion.vue')
  },
  {
    path: '/TutorialesHC',
    name: 'TutorialesHC',
    component: () => import(/* webpackChunkName: "about" */ '../components/Tutoriales/TutorialesHC.vue')
  },
  {
    path: '/TutorialesConfig',
    name: 'TutorialesConfig',
    component: () => import(/* webpackChunkName: "about" */ '../components/Tutoriales/TutorialesConfiguraciones.vue')
  },
  
  {
    path: '*', redirect: '/'
  }
  
]

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: 'history',
  routes
})



export default router
